body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


/* 26-07-2022*/
button.depositeBtn {
  padding: 10px;
  font-weight: bold;
}

.amntField {
  color: #fff;
  background: transparent;
  padding: 10px;
  /* background: none; */
  border: 1px grey solid;
    border-top: none;
    border-left: none;
    border-right: none;
    outline:none;
}
.amntField:active{
    outline:none;
}
/* @media only screen and (min-device-width: 768px){
  .side-drawer {
    
    width: 50%!important;
  }

} */
.gameImg_div:hover .gameImg {
  display: block;
 }
@media (min-width: 768px) and (max-width: 1024px) {
  .side-drawer {
    width: 50%!important;
  }

  .topHead {
    display: block!important;
    width: 100%!important;
    text-align: center;
}
.gameImg_div:hover .gameImg {
 display: none !important;
}
}
@media (min-width: 1025px) and (max-width: 1200px) {
  .side-drawer {
    width: 40%!important;
  }
  .topHead {
    display: block!important;
    width: 100%!important;
    text-align: center;
}
}



  @media (min-width: 300px) and (max-width: 768px) {
  .side-drawer {
    width: 100%!important;
  }
  .topHead {
    display: block!important;
    width: 100%!important;
    text-align: center;
}

.wallet1 a.nav-item.nav-link {
  font-size: 10px!important;
}


.wallet1 .nav {
  display: flex;
  flex-wrap: unset;
}
.mainTabs .wallet1 .nav-tabs a {
margin: 2px;
}

.mainTabs .wallet1 .balance h3 {
  font-size: 10px!important;
}


.home .home__banner {
  margin-top: 0px;
  height: 175px !important;
}


.homeIMg .w-100{
width: 100%!important;
height: 174px !important;
object-fit: cover;
}

/* .crisp-client .cc-52lo[data-full-view=true] .cc-kegp .cc-1oun .cc-1qth{

  margin-bottom: 52px !important;
} */
body .crisp-client .cc-52lo[data-full-view=true] .cc-kegp .cc-1oun {
  bottom: 88px!important;
}
img.PageLoaderImg {
  width: 100px;
  margin-left: -11px;
  margin-right: -1px;
  margin-top: 96%;
}


h4.game_name_Play.gamForMobile, .gameImg_div:hover .game_name_p.gamForMobile{
  display: none !important;
}

.gameImg_div:hover .gameImg {
  -webkit-filter: blur(0px) !important;
          filter: blur(0px) !important;
}
}

.slick-prev, .slick-next{
  top: 49% !important;
}




.loader_section.gameLOder {
  position: absolute;
  z-index: 999;
  top: 57%;
  margin-left: 37px;
  height: auto;
  left: 29%;
}